<div class="well p-3" style="border: 1px dashed #ccc; border-radius: 5px;">
  <!-- ส่วนคำแนะนำและปุ่ม -->
  <div class="text-center mb-3">
    <small>{{ description }}</small>
    @if((displayedExistingFiles?.length || 0) + (uploader?.queue?.length || 0) < maxFiles){
      <button
        type="button"
        (click)="fileInput.click()"
        class="btn btn-sm btn-custom btn-light ms-2"
        *ngIf="!disabled">
        {{'เพิ่มรูป'}}
      </button>
    }
  </div>

 @if(loading){
   <div class="text-center my-3">
     <div class="spinner-border text-primary" role="status">
       <span class="visually-hidden">Loading...</span>
     </div>
   </div>
 }

  <!-- แสดงไฟล์ที่อัพโหลดใหม่ -->
  @if( mode ==='grid'){
      <div *ngIf="uploader?.queue?.length > 0 || displayedExistingFiles?.length > 0" class="row g-2 mb-3">
        @for(item of uploader.queue; track $index ){
          <div class="col-md-4 mb-2">
            <div class="card h-100">
              <div class="position-relative">
                <img [src]="previewUrls[$index]" class="card-img-top p-2" style="height: 120px; object-fit: contain;">
                <button
                  *ngIf="!disabled"
                  type="button"
                  class="btn btn-sm btn-danger position-absolute top-0 end-0"
                  style="margin: 3px; z-index: 10;"
                  (click)="removeFile(item, $index)">
                  <i class="bi bi-trash"></i>
                </button>
              </div>
              <div class="card-body p-2 border-top">
                <p class="card-text small text-truncate mb-0" [title]="item?.file?.name">
                  {{item?.file?.name | slice:0:20}}{{item?.file?.name.length > 20 ? '...' : ''}}
                </p>
                <small class="text-muted">{{item?.file?.size/1024/1024 | number:'.2'}} MB</small>
              </div>
            </div>
          </div>
        }
        @for (file of displayedExistingFiles; track $index){
          <div class="col-md-4 mb-2">
            <div class="card h-100">
              <div class="position-relative">
                <img [src]="awsService.getImageUrl(file.id)" class="card-img-top p-2" style="height: 120px; object-fit: contain;">
                <button
                  *ngIf="!disabled"
                  type="button"
                  class="btn btn-sm btn-danger position-absolute top-0 end-0"
                  style="margin: 3px; z-index: 10;"
                  (click)="removeExistingFile($index)">
                  <i class="bi bi-trash"></i>
                </button>
              </div>
              <div class="card-body p-2 border-top">
                <p class="card-text small text-truncate mb-0" [title]="file.originalName">
                  {{file.originalName | slice:0:20}}{{file.originalName.length > 20 ? '...' : ''}}
                </p>
                <small class="text-muted">{{file.fileSize/1024/1024 | number:'.2'}} MB</small>
              </div>
            </div>
          </div>

        }
      </div>
  } @else if (mode === 'single') {
      <div *ngIf="uploader?.queue?.length > 0" class="text-center mb-3">
        <div class="card mx-auto" style="max-width: 300px;">
          <div class="position-relative">
            <img [src]="previewUrls[0]" class="card-img-top p-2" style="height: 150px; object-fit: contain;">
            <button
              *ngIf="!disabled"
              type="button"
              class="btn btn-sm btn-danger position-absolute top-0 end-0"
              style="margin: 3px; z-index: 10;"
              (click)="removeFile(uploader.queue[0], 0)">
              <i class="bi bi-trash"></i>
            </button>
          </div>
          <div class="card-body p-2 border-top">
            <p class="card-text small text-truncate mb-0" [title]="uploader?.queue[0]?.file?.name">
              {{uploader?.queue[0]?.file?.name | slice:0:30}}{{uploader?.queue[0]?.file?.name.length > 30 ? '...' : ''}}
            </p>
            <small class="text-muted">{{ uploader?.queue[0]?.file?.size/1024/1024 | number:'.2' }} MB</small>
          </div>
        </div>
      </div>

      <!-- ไฟล์เดิม (สำหรับรูปแบรนด์) -->
      @if(displayedExistingFiles?.length > 0 && !uploader?.queue?.length){
        <div class="text-center mb-3">
          <div class="card mx-auto" style="max-width: 300px;">
            <div class="position-relative">
              <img [src]="awsService.getImageUrl(displayedExistingFiles[0].id)" class="card-img-top p-2" style="height: 150px; object-fit: contain;">
              <button
                *ngIf="!disabled"
                type="button"
                class="btn btn-sm btn-danger position-absolute top-0 end-0"
                style="margin: 3px; z-index: 10;"
                (click)="removeExistingFile(0)">
                <i class="bi bi-trash"></i>
              </button>
            </div>
            <div class="card-body p-2 border-top">
              <p class="card-text small text-truncate mb-0" [title]="displayedExistingFiles[0].originalName">
                {{displayedExistingFiles[0].originalName | slice:0:30}}{{displayedExistingFiles[0].originalName.length > 30 ? '...' : ''}}
              </p>
              <small class="text-muted">{{ displayedExistingFiles[0].fileSize/1024/1024 | number:'.2' }} MB</small>
            </div>
          </div>
        </div>
      }
  }
  <!-- Drop Zone -->
  <div
    ng2FileDrop
    [ngClass]="{'nv-file-over': isFileOver}"
    (fileOver)="fileOverBase($event)"
    [uploader]="uploader"
    class="my-drop-zone text-muted text-center p-3"
    *ngIf="!hasFiles() && !disabled">
    <i class="bi bi-upload" style="font-size: 4rem; color: #ccc;"></i>
    <div class="mt-2 text-muted">Drag file here</div>
  </div>

  <!-- Hidden file input -->
  <input
    type="file"
    #fileInput
    ng2FileSelect
    [uploader]="uploader"
    [multiple]="multiple"
    style="display: none"
    accept="image/*"/>
</div>
