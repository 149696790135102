import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {FileUpLoad} from '../../../../generated-model/model';
import {FileItem, FileUploader, FileUploadModule} from 'ng2-file-upload';
import {AwsRest} from '../../../service/AwsRest';
import {DecimalPipe, NgClass, NgIf, SlicePipe} from '@angular/common';
import {ToastService} from '../../commons/toast/toast-service';
import {of} from 'rxjs';

@Component({
  selector: 'app-file-image',
  standalone: true,
  imports: [
    SlicePipe,
    DecimalPipe,
    NgClass,
    FileUploadModule,
    NgIf
  ],
  templateUrl: './file-image.component.html',
  styleUrl: './file-image.component.scss'
})
export class FileImageComponent implements OnInit, OnChanges, OnDestroy {
  @Input() description = 'Upload file';
  @Input() maxFiles = 1;
  @Input() multiple = false;
  @Input() mode: 'grid' | 'single' = 'grid';
  @Input() disabled = false;
  @Input() existingFiles: FileUpLoad[] = [];
  @Input() loading = false;

  @Output() filesChanged = new EventEmitter<{
    newFiles: File[],
    deletedFileIds: number[]
  }>();

  uploader: FileUploader;
  previewUrls: string[] = [];
  isFileOver = false;
  deletedFileIds: number[] = [];
  displayedExistingFiles: FileUpLoad[] = [];

  awsService = inject(AwsRest);
  toastService = inject(ToastService);

  ngOnInit() {
    this.initUploader();
    this.updateDisplayedFiles();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // เมื่อมีการเปลี่ยนแปลง existingFiles จากภายนอก
    if (changes['existingFiles']) {
      this.updateDisplayedFiles();
    }
  }

  // จัดการไฟล์ที่แสดงโดยกรองไฟล์ที่ถูกลบออก
  updateDisplayedFiles() {
    if (this.existingFiles) {
      this.displayedExistingFiles = this.existingFiles.filter(
        file => !this.deletedFileIds.includes(file.id)
      );
    } else {
      this.displayedExistingFiles = [];
    }
  }

  initUploader() {
    this.uploader = new FileUploader({
      url: '',
      itemAlias: 'file',
      autoUpload: false,
      allowedFileType: ['image']
    });

    this.uploader.onWhenAddingFileFailed = (item, filter) => {
      if (filter.name === 'fileType') {
        this.toastService.showWarning('รองรับเฉพาะไฟล์รูปภาพ (.jpg, .jpeg, .png)');
      } else {
        console.error('File failed validation', filter.name);
      }
    };

    this.uploader.onAfterAddingFile = fileItem => {
      const totalFiles = this.uploader.queue.length +
        (this.displayedExistingFiles?.length || 0);

      if (this.maxFiles > 0 && totalFiles > this.maxFiles) {
        this.toastService.showWarning(`สามารถอัพโหลดได้สูงสุด ${this.maxFiles} ไฟล์เท่านั้น`);
        this.uploader.removeFromQueue(fileItem);
        return;
      }

      // สร้าง preview
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.previewUrls.push(e.target.result);

        // ถ้าเป็นโหมด single ให้เก็บแค่ไฟล์เดียว
        if (this.mode === 'single' && this.uploader.queue.length > 1) {
          // เอาตัวเก่าออก
          this.previewUrls.shift();
          this.uploader.removeFromQueue(this.uploader.queue[0]);
        }

        this.emitChange();
      };
      reader.readAsDataURL(fileItem._file);
    };
  }

  removeFile(item: FileItem, index: number) {
    this.uploader.removeFromQueue(item);
    this.previewUrls.splice(index, 1);
    this.emitChange();
  }

  removeExistingFile(index: number) {
    if (this.displayedExistingFiles && this.displayedExistingFiles.length > index) {
      const fileId = this.displayedExistingFiles[index].id;

      if (fileId && !this.deletedFileIds.includes(fileId)) {
        this.deletedFileIds.push(fileId);
      }

      // อัปเดตรายการไฟล์ที่แสดง
      this.displayedExistingFiles.splice(index, 1);

      // แจ้ง parent component
      this.emitChange();
    }
  }

  fileOverBase(isOver: boolean) {
    this.isFileOver = isOver;
  }

  hasFiles(): boolean {
    return (this.uploader?.queue?.length > 0) ||
      (this.displayedExistingFiles?.length > 0);
  }

  emitChange() {
    const files = this.uploader.queue.map(item => item._file);
    this.filesChanged.emit({
      newFiles: files,
      deletedFileIds: this.deletedFileIds
    });
  }

  ngOnDestroy() {
    this.previewUrls.forEach(url => {
      if (url?.startsWith('blob:')) {
        URL.revokeObjectURL(url);
      }
    });
  }

  protected readonly of = of;
}
